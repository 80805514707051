import supabase from '../../lib/supabase'
import { Message } from '../../lib/supabase'

export const addMessage = async (message: Message) => {
	const { data, error } = await supabase.from('messages').insert([message])

	if (error) {
		throw new Error(error.message)
	}
	return data
}
