import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import ActivityBox from './ActivityBox'
import type { Activity } from 'lib/supabase'
import { Box, Paper, Typography } from '@mui/material'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Backdrop,
} from '@mui/material'
import { useNavigate, useOutletContext } from 'react-router'
import { groupBy, objectMap } from 'utils'
import Loading from 'components/Loading'
import { useUserContext } from 'contexts/UserContext'
import { useUpdateProfile } from 'api/profile'
import { useGroupsContext } from 'contexts/GroupsContext'
import { useUpdateActivity } from 'api/activity'
import useActivities from 'hooks/useActivities'
import ErrorComponent from 'components/ErrorComponent'
import introJs from 'intro.js'
import { track } from '@vercel/analytics'
import { WELCOME_STEP_COOKIE } from '../Welcome/messageFlowMachine'
import { MainOutletContext } from '../MainLayout'
import { getGroup } from 'api/group'
import { getProfile } from 'api/profile'
import {
	SubscriptionInfo,
	StripeData,
	normalizeSubscriptionStatus,
	getSubscriptionInfo,
} from 'vercel/stripe-utils'

const Dashboard: React.FC = () => {
	// const [range, setRange] = useState<SupportedRanges>('month')
	const [showTourDialog, setShowTourDialog] = useState(false)
	const [activeSubscription, setActiveSubscription] = useState(true)
	const [userIsAdmin, setUserIsAdmin] = useState(false)
	const [adminFullName, setAdminFullName] = useState('')
	const { profile } = useUserContext()
	const { userIsAdminOfGroup } = useGroupsContext()
	const navigate = useNavigate()
	const updateProfile = useUpdateProfile()
	const { setShowTopNav, setSettingsDisabled } =
		useOutletContext() as MainOutletContext

	useEffect(() => {
		setShowTopNav(true)
		setSettingsDisabled(false)
	}, [])

	useEffect(() => {
		if (profile) {
			// setRange(profile.activity_range)

			if (profile.active_group)
				setUserIsAdmin(userIsAdminOfGroup(profile.active_group))

			// check Stripe subscription status if profile is group admin and has a stripe_customer_id
			// how to make sure this not super chatty?
			if (userIsAdmin && profile.stripe_customer_id)
				checkStripeSubscription(profile.id, profile.stripe_customer_id)

			// SIGNED IN USER IS NOT GROUP ADMIN
			if (!userIsAdmin) {
				getGroup({
					groupId: profile?.active_group ?? '',
				}).then((gprops) => {
					getProfile({ profileId: gprops?.admin ?? '' }).then(
						(gadmin) => {
							setAdminFullName(gadmin.full_name)
							if (gadmin.stripe_customer_id)
								checkStripeSubscription(
									gadmin.id,
									gadmin.stripe_customer_id
								)
						}
					)
				})
			}
		}
	}, [profile])

	const checkStripeSubscription = async (uid: string, sid: string) => {
		const data: StripeData = await getSubscriptionInfo(sid)
		const sub = data.subscription as SubscriptionInfo

		// normalize sub.status to active, inactive, expired
		const curStatus = normalizeSubscriptionStatus(sub.status)
		setActiveSubscription(curStatus === 'expired' ? false : true)

		if (sub) {
			if (sub.status && curStatus != profile?.subscription_status) {
				// set newStatus to active or expired base on sub.status values
				const newStatus = normalizeSubscriptionStatus(sub.status)

				updateProfile.mutate({
					profileId: uid,
					profile: {
						subscription_status: newStatus as
							| 'active'
							| 'inactive'
							| 'expired'
							| null
							| undefined,
						subscribed_at: DateTime.now().toISO(),
						trialing: sub.trialing,
					},
				})
			}
		}
	}

	const activities = useActivities({
		groupId: profile?.active_group || '',
	})

	if (activities?.isError) {
		console.error(activities.error)
	}

	useEffect(() => {
		if (
			activities?.isSuccess &&
			localStorage.getItem(WELCOME_STEP_COOKIE)
		) {
			setShowTourDialog(true)
		}
	}, [activities?.isSuccess, activities?.dataUpdatedAt])

	const startTour = () => {
		const tour = introJs()
		tour.onstart(() => {
			localStorage.removeItem(WELCOME_STEP_COOKIE)
			track('taketour-wizard')
			setShowTourDialog(false)
		})
		tour.start()
	}

	const updateActivity = useUpdateActivity()

	const [processingButtonPress, setProcessingButtonPress] = useState<{
		status: boolean
		id?: string
	}>({ status: false, id: undefined })

	const handleGoing = async (activity: Activity) => {
		const { id: activityId, ins: currentIns, outs: currentOuts } = activity
		if (!profile) return

		setProcessingButtonPress({
			status: true,
			id: activityId,
		})
		const inIndex = currentIns.findIndex((i) => i === profile.id)
		const isIn = inIndex > -1
		const newIns = [...currentIns]
		const outIndex = currentOuts.findIndex((o) => o === profile.id)
		const isOut = outIndex > -1
		const newOuts = [...currentOuts]

		// handle the scenarios that arise when selecting the 'going' button
		if (!isIn && !isOut) {
			// add to ins
			newIns.push(profile.id)
		} else if (!isIn && isOut) {
			// remove from outs and add to ins
			newOuts.splice(outIndex, 1)
			newIns.push(profile.id)
		} else if (isIn) {
			// remove from ins
			newIns.splice(inIndex, 1)
		} else {
			throw new Error('issue in handleGoing function')
		}

		await updateActivity.mutateAsync({
			activityId: activityId,
			activity: {
				ins: newIns,
				outs: newOuts,
			},
		})

		track('in')

		setProcessingButtonPress({
			status: false,
			id: undefined,
		})
	}

	const handleNotGoing = async (activity: Activity) => {
		const { id: activityId, ins: currentIns, outs: currentOuts } = activity
		if (!profile) return

		setProcessingButtonPress({
			status: true,
			id: activityId,
		})
		const inIndex = currentIns.findIndex((i) => i === profile.id)
		const isIn = inIndex > -1
		const newIns = [...currentIns]
		const outIndex = currentOuts.findIndex((o) => o === profile.id)
		const isOut = outIndex > -1
		const newOuts = [...currentOuts]

		// handle the scenarios that arise when selecting the 'going' button
		if (!isIn && !isOut) {
			// add to outs
			newOuts.push(profile.id)
		} else if (isIn && !isOut) {
			// remove from ins and add to outs
			newIns.splice(inIndex, 1)
			newOuts.push(profile.id)
		} else if (isOut) {
			// remove from outs
			newOuts.splice(outIndex, 1)
		} else {
			throw new Error('issue in handleNotGoing function')
		}

		await updateActivity.mutateAsync({
			activityId: activityId,
			activity: {
				ins: newIns,
				outs: newOuts,
			},
		})

		setProcessingButtonPress({
			status: false,
			id: undefined,
		})
	}

	const groupActivitiesAndReturnJSX = (
		activities: Activity[],
		expired: boolean
	) => {
		let filteredActivities = []
		if (expired) {
			filteredActivities = activities.filter(
				(obj) => obj.datetime <= DateTime.now().toISODate()
			)
		} else {
			filteredActivities = activities.filter(
				(obj) => obj.datetime > DateTime.now().toISODate()
			)
		}

		const groups = groupBy(
			filteredActivities,
			(activity) => DateTime.fromISO(activity.datetime).toISODate() || ''
		)

		const elements = objectMap(groups, (group, key, i) => {
			return (
				<Box key={key} sx={{ margin: '10px' }}>
					{/* <Button onClick={confirmTour}>Start Tour</Button> */}
					<Paper elevation={3} sx={{ paddingBottom: '5px' }}>
						<section key={key}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									overflow: 'hidden',
								}}
							>
								<div
									style={{
										minWidth: '6.5rem',
										paddingTop: '20px',
										borderRight: '1px solid #dddddd',
									}}
								>
									<Typography fontWeight={'bold'}>
										{DateTime.fromISO(key).toFormat('cccc')}
									</Typography>
									<Typography
										fontWeight={'bold'}
										fontSize={'2.0rem'}
									>
										{DateTime.fromISO(key).toFormat('d')}
									</Typography>
									<Typography>
										{DateTime.fromISO(key).toFormat('LLLL')}
									</Typography>
								</div>
								<div
									style={{
										width: '100%',
										overflow: 'hidden',
									}}
								>
									{group.map((activity, j) => {
										return (
											<ActivityBox
												key={activity.id}
												activity={activity}
												buttonsDisabled={
													processingButtonPress.status &&
													processingButtonPress.id ===
														activity.id
												}
												chatDisabled={
													activity.ins.indexOf(
														profile?.id || ''
													) === -1
												}
												introSteps={i === 0 && j === 0}
												onGoing={() =>
													handleGoing(activity)
												}
												onNotGoing={() =>
													handleNotGoing(activity)
												}
												onMoreInfo={() =>
													navigate(
														`/activity/${activity.id}`
													)
												}
											/>
										)
									})}
								</div>
							</div>
						</section>
					</Paper>
				</Box>
			)
		})

		const jsx: JSX.Element[] = []
		for (const key in elements) {
			jsx.push(elements[key] as JSX.Element)
		}

		if (jsx.length == 0)
			return (
				<div>
					<p>Your group has no upcoming activities.</p>
					<Typography paddingX={'40px'}>
						To create an activity tap the&nbsp;
						<strong>Create New Activity</strong> button below.
					</Typography>
				</div>
			)
		return jsx
	}

	return (
		<Box id='dashboard'>
			<Backdrop
				sx={{ color: '#fff', zIndex: 10000 }}
				open={!activeSubscription}
			>
				<div
					style={{
						background: '#333333',
						margin: '0px 20px',
						borderRadius: '10px',
					}}
				>
					<div style={{ display: userIsAdmin ? 'block' : 'none' }}>
						<Typography
							variant='h6'
							style={{
								textAlign: 'center',
								padding: '20px',
							}}
						>
							Your subscription has expired. Please renew to
							continue using Joyner.
						</Typography>
						<div
							style={{
								textAlign: 'center',
								margin: '20px 0px 30px 0px',
							}}
						>
							<Button
								variant='contained'
								color='success'
								onClick={() => navigate('/account')}
							>
								Renew Subscription
							</Button>
						</div>
					</div>
					<div style={{ display: userIsAdmin ? 'none' : 'block' }}>
						<Typography
							variant='h6'
							style={{
								textAlign: 'center',
								padding: '20px',
							}}
						>
							The owner of this group&apos;s subscription has
							expired. Please contact {adminFullName} to renew
							their subscription.
						</Typography>
						<Typography
							variant='h6'
							style={{
								textAlign: 'center',
								padding: '20px',
							}}
						>
							Tap the button below to learn more about starting
							your own subscription.
						</Typography>
						<div
							style={{
								textAlign: 'center',
								margin: '20px 0px 30px 0px',
							}}
						>
							<Button
								variant='contained'
								color='success'
								onClick={() => navigate('/account')}
							>
								Subscription Information
							</Button>
						</div>
					</div>
				</div>
			</Backdrop>

			{/* TEE TIME SELECTORS */}
			{profile ? (
				<section
					className='activities'
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						textAlign: 'center',
						overflowY: 'scroll',
						overflowX: 'hidden',
						paddingBottom: '80px',
					}}
				>
					{activities?.isSuccess ? (
						<>
							{activities.data.length ? (
								<>
									<>
										{groupActivitiesAndReturnJSX(
											activities.data,
											false
										)}
									</>
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												marginTop: '20px',
											}}
										></div>
									</>
								</>
							) : (
								<div style={{ display: 'block' }}>
									<p>Should not ever get here</p>
								</div>
							)}
						</>
					) : activities?.isError ? (
						<ErrorComponent />
					) : (
						<div
							style={{
								height: '100vh',
							}}
						>
							<Loading />
						</div>
					)}
				</section>
			) : (
				<Loading />
			)}

			<Dialog open={showTourDialog}>
				<DialogTitle>Welcome to Joyner</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Tap Start to take a quick tour of Joyner&apos;s
						features.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={startTour} variant='contained'>
						Start
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
export default Dashboard
