import React from 'react'
import { Stack, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useGetActivity } from 'api/activity'

type ActivityLinkBoxProps = {
	activityId: string | null
	authorBox: boolean
}

const ActivityLinkBox: React.FC<ActivityLinkBoxProps> = ({
	activityId,
	authorBox,
}) => {
	const activity = useGetActivity({
		activityId: activityId!,
		options: { enabled: !!activityId },
	})

	return (
		<div
			style={{
				background: '#ffffff',
				borderLeft: '6px solid #999999',
				padding: '3px 5px',
				width: '90%',
			}}
		>
			<Stack
				direction={'row'}
				justifyContent={'space-between'}
				alignContent={'baseline'}
				paddingTop={'5px'}
			>
				<Typography>{activity.data?.name}</Typography>
				<Typography>
					<KeyboardArrowRightIcon />
				</Typography>
			</Stack>
		</div>
	)
}
export default ActivityLinkBox
