import React from 'react'
import { Stack, Typography, Box, IconButton, Button } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
// import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CloseIcon from '@mui/icons-material/Close'
import { useGetActivity } from 'api/activity'
import { useGetVenue } from 'api/venue'
import { useNavigate } from 'react-router'

import { Profile, Message } from 'lib/supabase'
import { DateTime } from 'luxon'

function formatPhoneNumber(phoneNumberString: string) {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
	if (match) {
		const intlCode = match[1] ? '+1 ' : ''
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
	}
	return null
}

type ContactPopoverProps = {
	contact: Profile
	onCopy: (contentToCopy: string | '') => void
	onCancel: () => void
}

export const ContactPopover: React.FC<ContactPopoverProps> = ({
	contact,
	onCopy,
	onCancel,
}) => {
	const copyToClipboard = () => {
		let copytext = ''
		copytext += contact.full_name + '\n'
		copytext += formatPhoneNumber(contact.phone_number) + '\n'
		copytext += contact.email + '\n'
		navigator.clipboard.writeText(copytext)

		onCopy(copytext)
	}

	return (
		<div style={{ padding: '20px', width: '90vw' }}>
			<Stack direction={'column'} gap={2}>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography
						fontSize={'1.2rem'}
						fontWeight={'500'}
						textTransform={'uppercase'}
					>
						{contact.full_name}
					</Typography>
					<IconButton onClick={onCancel} disableRipple>
						<CloseIcon />
					</IconButton>
				</Stack>
				<Stack direction={'row'} gap={2} alignItems={'center'}>
					<IconButton
						color='primary'
						href={`tel:${contact.phone_number}`}
					>
						<PhoneForwardedIcon />
					</IconButton>
					<Typography>
						{formatPhoneNumber(contact.phone_number)}
					</Typography>
				</Stack>
				<Stack direction={'row'} gap={2} alignItems={'center'}>
					<IconButton
						color='primary'
						href={`mailto:${contact.email}`}
					>
						<ForwardToInboxIcon />
					</IconButton>
					<Typography>{contact.email}</Typography>
				</Stack>
				<div
					style={{
						marginTop: '10px',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Button
						startIcon={<ContentCopyIcon />}
						variant='contained'
						fullWidth
						onClick={copyToClipboard}
					>
						Copy Contact Info
					</Button>
				</div>
			</Stack>
		</div>
	)
}

type ActivityPopoverProps = {
	activityId: string
	onCopy: (contentToCopy: string | '') => void
	onCancel: () => void
}

export const ActivityPopover: React.FC<ActivityPopoverProps> = ({
	activityId,
	onCopy,
	onCancel,
}) => {
	const activity = useGetActivity({
		activityId: activityId!,
		options: { enabled: !!activityId },
	})
	const venue = useGetVenue({
		venueId: activity.data?.venue_id || '',
	})
	const navigate = useNavigate()

	const copyToClipboard = () => {
		let copytext = ''
		copytext += activity.data?.name + '\n'
		copytext +=
			DateTime.fromISO(activity.data?.datetime || '')
				.toFormat('cccc, LLL y h:mm a ZZZZ')
				.toLocaleString() + '\n'
		copytext += venue.data?.name + '\n'

		onCopy(copytext)
	}

	return (
		<Box padding={'20px'} width={'90vw'}>
			<Stack direction={'column'} gap={0}>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography
						fontSize={'1.2rem'}
						fontWeight={'500'}
						textTransform={'uppercase'}
					>
						{activity.data?.name}
					</Typography>
					<IconButton onClick={onCancel} size={'large'} disableRipple>
						<CloseIcon />
					</IconButton>
				</Stack>
				<Typography>
					{activity.data?.datetime &&
						DateTime.fromISO(activity.data?.datetime)
							.toFormat('cccc, LLL y h:mm a ZZZZ')
							.toLocaleString()}
				</Typography>
				<Typography>{venue.data?.name}</Typography>
				<Stack direction={'row'} marginTop={'20px'} gap={2}>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							navigate(`/activity/${activityId}/chatpage`)
						}}
					>
						View Activity
					</Button>
					<Button variant='outlined' onClick={copyToClipboard}>
						Copy Activity
					</Button>
				</Stack>
			</Stack>
		</Box>
	)
}

type MessagePopoverProps = {
	message: Message
	profile: Profile | null
	onCopy: (contentToCopy: string | '') => void
	// onEdit: (messageId: string) => void
	onDelete: (messageId: string) => void
	onCancel: () => void
}

export const MessagePopover: React.FC<MessagePopoverProps> = ({
	message,
	profile,
	onCopy,
	// onEdit,
	onDelete,
	onCancel,
}) => {
	return (
		<div style={{ padding: '20px', width: '90vw' }}>
			<Stack direction={'column'} gap={0}>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography
						fontSize={'1.2rem'}
						fontWeight={'500'}
						textTransform={'uppercase'}
					>
						Message Actions
					</Typography>
					<IconButton onClick={onCancel} size={'large'} disableRipple>
						<CloseIcon />
					</IconButton>
				</Stack>
				<Stack
					direction={'row'}
					gap={1}
					justifyContent={'sapce-around'}
					paddingTop={'20px'}
				>
					<Button
						variant='contained'
						color='primary'
						onClick={() => onCopy(message.body)}
					>
						Copy Message
					</Button>
					{/* <Button
						variant='outlined'
						color='primary'
						disabled
						onClick={() => onEdit(message.id)}
					>
						Edit...
					</Button> */}
					<Button
						variant='outlined'
						color='error'
						disabled={profile?.id !== message.sender}
						onClick={() => onDelete(message.id)}
					>
						Delete Message
					</Button>
				</Stack>
			</Stack>
		</div>
	)
}
