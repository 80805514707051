import queryClient from '../../lib/reactQuery'
import { TABLE_PRIVATE_CHATS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'
import type { PrivateChat } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreatePrivateChateArgs = {
	conversation: PrivateChat
}
export const createConversation = async ({
	conversation,
}: CreatePrivateChateArgs) =>
	createGeneric({ tableName: TABLE_PRIVATE_CHATS, data: conversation })

type UseCreateConversationOptions = {
	options?: MutationConfig<typeof createConversation>
}
export const useCreateConversation = ({
	options,
}: UseCreateConversationOptions = {}) => {
	return useMutation({
		onSuccess: (conversation) => {
			queryClient.setQueryData(
				['conversation', conversation.id],
				conversation
			)
		},
		...options,
		mutationFn: createConversation,
	})
}
