import React, { useState, useEffect } from 'react'
import {
	Button,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	Checkbox,
	TextField,
	Typography,
	Avatar,
	AvatarGroup,
	FormGroup,
	FormControlLabel,
	Switch,
	Paper,
	Divider,
	Stack,
	Alert,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import { getManyByGroupId } from 'api/profile'
import type { Profile } from 'lib/supabase'
import { stringAvatar } from 'utils'

type Props = {
	isOpen: boolean
	onCancel: (actionTaken: boolean) => void
	onUpdateDeleteAction: () => void
	onSendNotifications: (selected: string[], messagage: string) => void
	activeGroup: string | undefined
	title: string
	textFieldLabel: string
	selectAll: boolean | true
	forceSendAll: boolean | false
	showConfirm: boolean | false
	showConfirmText: string
	dialogType: string
	dialogAction: string
	objectType: string
}

type MuiColor =
	| 'primary'
	| 'error'
	| 'warning'
	| 'success'
	| 'info'
	| 'inherit'
	| 'secondary'

type ConfirmWidgetProps = {
	confirmed: boolean
	setConfirmed: (value: boolean) => void
	dialogAction: string
	showConfirmText: string
}

const ConfirmWidget: React.FC<ConfirmWidgetProps> = ({
	confirmed,
	setConfirmed,
	dialogAction,
	showConfirmText,
}) => {
	let backgroundColor = ''
	let fontColor = ''
	let switchColor = '' as MuiColor

	if (dialogAction === 'delete') {
		backgroundColor = '#ffcdd2'
		switchColor = 'error'
		fontColor = '#b71c1c'
	} else if (dialogAction === 'update') {
		backgroundColor = '#e8f5e9'
		switchColor = 'success'
		fontColor = '#1b5e20'
	} else if (dialogAction === 'reset' || dialogAction === 'close') {
		backgroundColor = '#ffe0b2'
		switchColor = 'warning'
		fontColor = '#ef6c00'
	} else {
		backgroundColor = '#b3e5fc'
		switchColor = 'primary'
		fontColor = '#01579b'
	}

	return (
		<Paper
			style={{
				background: backgroundColor,
				padding: '5px 10px',
				marginBottom: '15px',
			}}
			elevation={3}
		>
			<FormGroup>
				<FormControlLabel
					control={
						<Switch
							color={switchColor}
							onChange={() => setConfirmed(!confirmed)}
						/>
					}
					label={
						<Typography fontWeight={'500'}>
							{showConfirmText}
						</Typography>
					}
					style={{
						color: fontColor,
						textTransform: 'uppercase',
					}}
				/>
			</FormGroup>
		</Paper>
	)
}

type ActionTakenButtonProps = {
	stepCount: number
	showConfirm: boolean
	confirmed: boolean
	dialogType: string
	dialogAction: string
	objectType: string
	forceSendAll: boolean
	onClick: () => void
}

const ActionTakenButton: React.FC<ActionTakenButtonProps> = ({
	stepCount,
	showConfirm,
	confirmed,
	objectType,
	dialogType,
	dialogAction,
	forceSendAll,
	onClick,
}) => {
	console.log(
		objectType,
		dialogType,
		dialogAction,
		stepCount,
		forceSendAll,
		showConfirm,
		confirmed
	)
	// HANDLE BUTTON TEXT CASES
	let newButtonText = ''
	if (dialogAction === 'update') newButtonText = 'Save Changes'
	if (dialogAction === 'delete') newButtonText = 'Delete'

	// HANDLE BUTTON COLOR CASES
	let newColor = 'primary' as MuiColor

	// HANDLE BUTTON VARIANT CASES
	let newVariant = 'contained' as 'contained' | 'outlined' | 'text'

	// HANDLE BUTTON DISABLED CASES
	let newDisabled = false
	if (stepCount === 0 && dialogType === 'confirm' && !confirmed) {
		newDisabled = true
	}

	// HANDLE BUTTON STYLE CASES
	const newStyle: React.CSSProperties = {}
	// newStyle.display = getCancelButtonVisibility(stepCount, forceSendAll)
	// 	? 'block'
	// 	: 'none'

	return (
		<Button
			fullWidth
			onClick={onClick}
			variant={newVariant}
			color={newColor}
			style={newStyle}
			disabled={newDisabled}
		>
			{newButtonText}
		</Button>
	)
}

type DialogMessageProps = {
	objectType: string
	dialogType: string
	dialogAction: string
	stepCount: number
}

const DialogMessage: React.FC<DialogMessageProps> = ({
	objectType,
	dialogType,
	dialogAction,
	stepCount,
}) => {
	let message: string = 'Dialog message goes here'
	if (
		(objectType === 'activity' || objectType === 'poll') &&
		dialogAction === 'update' &&
		stepCount === 0
	) {
		message = 'Do you want to notify group members about the change(s)?'
	} else if (
		(objectType === 'activity' || objectType === 'poll') &&
		dialogAction === 'delete' &&
		stepCount === 0
	) {
		message =
			'Do you want to notify group members about the deleted ' +
			objectType +
			'?'
	} else if (
		(objectType === 'activity' || objectType === 'poll') &&
		(dialogAction === 'update' || dialogAction === 'new') &&
		stepCount === 2
	) {
		message =
			String(objectType).charAt(0).toUpperCase() +
			String(objectType).slice(1) +
			' information is automatically included in the notification.  You can provide additional information below.'
	} else if (
		(objectType === 'activity' || objectType === 'poll') &&
		dialogAction === 'new' &&
		stepCount === 0
	) {
		message =
			'Do you want to notify group members about the new ' +
			objectType +
			'?'
	} else if (stepCount === 1) {
		message = 'Select recipients for the notification'
	}

	return (
		<Typography
			fontSize='0.9rem'
			color='#333333'
			paddingBottom='10px'
			paddingTop='10px'
		>
			{message}
		</Typography>
	)
}

type AlertWidgetProps = {
	objectType: string
	dialogAction: string
}

const AlertWidget: React.FC<AlertWidgetProps> = ({
	objectType,
	dialogAction,
}) => {
	let message = ''
	if (dialogAction === 'update') {
		message = 'Changes successfully saved'
	} else if (dialogAction === 'delete') {
		message =
			String(objectType).charAt(0).toUpperCase() +
			String(objectType).slice(1) +
			' successfully deleted'
	}
	return (
		<Alert
			icon={<CheckIcon fontSize='inherit' />}
			severity='success'
			style={{
				display:
					dialogAction === 'update' || dialogAction === 'delete'
						? 'flex'
						: 'none',
			}}
		>
			{message}
		</Alert>
	)
}

const SendNotificationDialog: React.FC<Props> = ({
	isOpen,
	onCancel,
	onSendNotifications,
	onUpdateDeleteAction,
	activeGroup,
	title,
	textFieldLabel,
	selectAll,
	forceSendAll,
	showConfirm,
	showConfirmText,
	dialogType,
	dialogAction,
	objectType,
}) => {
	const [checkedMembers, setCheckedMembers] = useState<string[]>([])
	const [members, setMembers] = useState([] as Profile[])
	const [stepCount, setStepCount] = useState(0)
	const [messageToSend, setMessageToSend] = useState('')
	const [confirmed, setConfirmed] = useState(false)
	const [actionTaken, setActionTaken] = useState(false)

	useEffect(() => {
		if (!activeGroup) return
		if (objectType === 'poll' && showConfirm) setStepCount(0)
		if (dialogAction === 'new') setActionTaken(true)
		if (dialogAction === 'message') setStepCount(1)
		setConfirmed(false)
		if (selectAll) {
			getManyByGroupId({ groupId: activeGroup }).then((response) => {
				setMembers(response)
				setCheckedMembers(response.map((member) => member.id))
			})
		} else {
			setCheckedMembers([])
		}
	}, [isOpen])

	const handleToggle = (uid: string) => {
		const currentIndex = checkedMembers.indexOf(uid)
		const newChecked = [...checkedMembers]
		if (currentIndex === -1) {
			newChecked.push(uid)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setCheckedMembers(newChecked)
	}

	const handleToggleAll = () => {
		if (checkedMembers.length === members.length) {
			setCheckedMembers([])
		} else {
			setCheckedMembers(members.map((member) => member.id))
		}
	}

	const getGroupMembersList = () => {
		return (
			<div>
				<Button style={{ padding: '0px' }} onClick={handleToggleAll}>
					Select{' '}
					{checkedMembers.length === members.length
						? ' None'
						: ' All'}
				</Button>
				<List dense style={{ paddingTop: '0px' }}>
					{members &&
						members.map((member: Profile) => (
							<ListItem key={member.id} disablePadding>
								<ListItemButton
									dense
									onClick={() => handleToggle(member.id)}
								>
									<ListItemIcon
										style={{
											minWidth: '20px',
										}}
									>
										<Checkbox
											edge='start'
											style={{ padding: '0px' }}
											checked={checkedMembers.includes(
												member.id
											)}
											disableRipple
										/>
									</ListItemIcon>
									<ListItemText primary={member.full_name} />
								</ListItemButton>
							</ListItem>
						))}
				</List>
			</div>
		)
	}

	const selectedMembers = members.filter((member) =>
		checkedMembers.includes(member.id)
	)

	const handleUpdateDeleteAction = () => {
		console.log(
			'SendNotificationDialog::handleUpdateDeleteAction: ',
			dialogType,
			dialogAction
		)
		onUpdateDeleteAction()
		setActionTaken(true)
	}

	const handleBack = () => {
		setStepCount(stepCount - 1)
	}

	const handleNext = () => {
		setStepCount(stepCount + 1)
	}

	const handleCancel = () => {
		onCancel(actionTaken)
	}

	return (
		<Dialog fullWidth open={isOpen} onClose={handleCancel}>
			<DialogTitle>{title}</DialogTitle>
			<IconButton
				size='small'
				disableRipple
				onClick={handleCancel}
				style={{
					position: 'absolute',
					right: 8,
					top: 15,
					color: 'grey',
				}}
			>
				<CancelIcon />
			</IconButton>
			<DialogContent style={{ paddingTop: '0px' }}>
				<DialogContentText>
					{/* STEP 0 - CONFIRM WIDGET */}
					<div
						style={{
							display:
								stepCount === 0 &&
								dialogType === 'confirm' &&
								!actionTaken
									? 'block'
									: 'none',
						}}
					>
						<ConfirmWidget
							confirmed={confirmed}
							setConfirmed={setConfirmed}
							dialogAction={dialogAction}
							showConfirmText={showConfirmText}
						/>
					</div>
					<div
						style={{
							display:
								stepCount === 0 &&
								dialogType === 'confirm' &&
								actionTaken
									? 'block'
									: 'none',
						}}
					>
						<AlertWidget
							objectType={objectType}
							dialogAction={dialogAction}
						/>

						{/* <Alert
							icon={<CheckIcon fontSize='inherit' />}
							severity='success'
							style={{
								display: actionTaken ? 'block' : 'none',
							}}
						></Alert> */}
					</div>

					{/* STEP 0 - YES/NO SEND NOTIFICATIONS */}
					<div
						style={{
							display:
								stepCount === 0 &&
								(dialogType === 'confirm' ||
									dialogType === 'notify') &&
								actionTaken
									? 'block'
									: 'none',
						}}
					>
						<Stack>
							<Divider style={{ marginTop: '30px' }}>
								<Typography
									fontWeight={'500'}
									textAlign={'center'}
								>
									Notify the Group?
								</Typography>
							</Divider>
							<DialogMessage
								objectType={objectType}
								dialogType={dialogType}
								dialogAction={dialogAction}
								stepCount={stepCount}
							/>
						</Stack>
					</div>

					{/* SELECT RECIPIENTS - STEP 1 */}
					<div
						style={{
							display: stepCount === 1 ? 'block' : 'none',
						}}
					>
						<DialogMessage
							objectType={objectType}
							dialogType={dialogType}
							dialogAction={dialogAction}
							stepCount={stepCount}
						/>
						<div>{activeGroup && getGroupMembersList()}</div>
					</div>

					{/* STEP 2 - ADD MESSAGE */}
					<div
						style={{
							display: stepCount === 2 ? 'block' : 'none',
						}}
					>
						<Typography>
							{checkedMembers.length} recipients selected
							<AvatarGroup
								max={10}
								spacing={12}
								style={{
									display: 'flex',
									justifyContent: 'left',
									paddingTop: '10px',
								}}
							>
								{selectedMembers.map((member) => (
									<Avatar
										key={member.full_name}
										style={{
											width: 36,
											height: 36,
											fontSize: '1.0rem',
											textTransform: 'uppercase',
										}}
										{...stringAvatar(member.full_name)}
									/>
								))}
							</AvatarGroup>
						</Typography>
						<DialogMessage
							objectType={objectType}
							dialogType={dialogType}
							dialogAction={dialogAction}
							stepCount={stepCount}
						/>
						<TextField
							style={{ marginTop: '10px' }}
							label={textFieldLabel}
							fullWidth
							multiline
							rows={4}
							value={messageToSend}
							onChange={(e) => setMessageToSend(e.target.value)}
						/>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions
				style={{
					boxShadow: '0px -5px 5px -5px #bdbdbd',
				}}
			>
				<Stack
					width={'100%'}
					marginBottom={'20px'}
					marginX={'5%'}
					paddingTop={'5px'}
				>
					{/* STEP 0 BUTTONS !ACTIONTAKEN */}
					<div
						style={{
							display:
								stepCount === 0 && !actionTaken
									? 'block'
									: 'none',
						}}
					>
						<Stack
							direction={'row'}
							gap={'10px'}
							justifyContent={'space-between'}
						>
							<Button onClick={handleCancel}>Cancel</Button>
							<ActionTakenButton
								stepCount={stepCount}
								showConfirm={showConfirm}
								confirmed={confirmed}
								dialogType={dialogType}
								dialogAction={dialogAction}
								objectType={objectType}
								forceSendAll={forceSendAll}
								onClick={handleUpdateDeleteAction}
							/>
						</Stack>
					</div>

					{/* STEP 0 BUTTONS ACTIONTAKEN */}
					<div
						style={{
							display:
								stepCount === 0 && actionTaken
									? 'block'
									: 'none',
						}}
					>
						<Stack
							direction={'row'}
							gap={'10px'}
							justifyContent={'space-between'}
						>
							<Button variant='outlined' onClick={handleCancel}>
								No I don&apos;t
							</Button>
							<Button
								variant='contained'
								onClick={() => setStepCount(1)}
							>
								Yes I would
							</Button>
						</Stack>
					</div>

					{/* STEP 1 BUTTONS w/ VALIDLIST */}
					<div
						style={{ display: stepCount === 1 ? 'block' : 'none' }}
					>
						<Stack
							direction={'row'}
							gap={'10px'}
							justifyContent={'space-between'}
						>
							<Button onClick={handleCancel}>Cancel</Button>
							<Button
								variant='contained'
								fullWidth
								disabled={checkedMembers.length === 0}
								onClick={handleNext}
							>
								Next
							</Button>
						</Stack>
					</div>

					{/* STEP 2 BUTTONS */}
					<div
						style={{ display: stepCount === 2 ? 'block' : 'none' }}
					>
						<Stack
							direction={'row'}
							gap={'10px'}
							justifyContent={'space-between'}
						>
							<Button onClick={handleCancel}>Cancel</Button>
							<Button variant='outlined' onClick={handleBack}>
								Back
							</Button>
							<Button
								fullWidth
								variant='contained'
								startIcon={<SendIcon />}
								onClick={() =>
									onSendNotifications(
										checkedMembers,
										messageToSend
									)
								}
							>
								Send
							</Button>
						</Stack>
					</div>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}

export default SendNotificationDialog
