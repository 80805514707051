import { sendNotification } from '../vercel/apicalls'

export function distributeNotifications(
	notificationTopic: string,
	notificationType: string,
	recipientList: string[],
	addlMessage: string,
	senderFullName: string,
	groupId: string,
	objectId: string
) {
	const host = import.meta.env.VITE_VERCELAPI_HOST
	const efurl = host + '/email/' + notificationTopic + '-' + notificationType
	const sfurl = host + '/twilio/' + notificationTopic + '-' + notificationType

	let parms = ''
	parms += '?sid=' + encodeURIComponent(senderFullName || '')
	parms += '&gid=' + encodeURIComponent(groupId || '')
	parms += '&oid=' + encodeURIComponent(objectId || '')
	parms += '&msg=' + encodeURIComponent(addlMessage)
	parms += '&rec=' + encodeURIComponent(recipientList.join(','))

	sendNotification(efurl + parms)
	sendNotification(sfurl + parms)
}
