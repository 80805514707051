import {
	useGetChatsByUserId as useGetConversations,
	getChatsQueryKey as getConversationsQueryKey,
} from 'api/conversations'
import queryClient from 'lib/reactQuery'
import supabase, { TABLE_PRIVATE_CHATS, type PrivateChat } from 'lib/supabase'
import { useEffect } from 'react'
import { getQueryKey } from 'api/conversations'
import { useUserContext } from 'contexts/UserContext'

type Args = {
	userId: string
	groupId: string
}

const useConversations = ({ userId, groupId }: Args) => {
	const conversations = useGetConversations({
		userId,
		groupId,
	})
	const { activeGroup } = useUserContext()

	useEffect(() => {
		const sub = supabase
			.channel('conversations-db-changes')
			.on(
				'postgres_changes',
				{ event: '*', schema: 'public', table: TABLE_PRIVATE_CHATS },
				async (payload) => {
					console.log('useConverations::Payload::', payload.eventType)

					if (payload.eventType === 'DELETE') {
						queryClient.removeQueries({
							queryKey: getQueryKey({
								conversationId: (payload.old as PrivateChat).id,
							}),
						})
						await queryClient.invalidateQueries({
							queryKey: getConversationsQueryKey({
								userId,
								groupId,
							}),
						})
					}

					if (
						payload.eventType === 'UPDATE' &&
						conversations.data?.findIndex(
							(a) => a.id === (payload.new as PrivateChat).id
						) !== -1
					) {
						await queryClient.setQueryData(
							getQueryKey({
								conversationId: (payload.new as PrivateChat).id,
							}),
							payload.new
						)
						await queryClient.invalidateQueries({
							queryKey: getConversationsQueryKey({
								userId,
								groupId,
							}),
						})
					}

					if (
						payload.eventType === 'INSERT' &&
						(payload.new as PrivateChat).group_id ===
							activeGroup?.id
					) {
						await queryClient.setQueryData(
							getQueryKey({
								conversationId: (payload.new as PrivateChat).id,
							}),
							payload.new
						)
						await queryClient.refetchQueries({
							queryKey: getConversationsQueryKey({
								userId,
								groupId,
							}),
						})
					}
				}
			)
			.subscribe((status, err) => {
				if (status === 'CHANNEL_ERROR' || status === 'TIMED_OUT') {
					if (err) console.error(err)
					sub.unsubscribe()
				}
			})

		return () => {
			sub.unsubscribe()
		}
	}, [supabase, conversations.data])

	return conversations
}

export default useConversations
