import React from 'react'
import logo from '../images/joyner-logo.svg'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'

type Props = {
	// Define the props for your component here
	leftComponent?: React.ReactNode
	// title overrides centerComponent
	title?: string
	centerComponent?: React.ReactNode
	rightComponent?: React.ReactNode
	subheader?: React.ReactNode
}

const AppHeader = React.forwardRef<unknown, Props>((props, ref) => {
	const [headerDefined] = React.useState<boolean>(() => {
		return (
			props.title != null ||
			props.centerComponent != null ||
			props.leftComponent != null ||
			props.rightComponent != null
		)
	})

	const getTitleValue = (): React.ReactNode => {
		const { title, centerComponent } = props

		if (title) {
			return (
				<Typography
					component='div'
					sx={{
						flexGrow: 1,
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						fontSize: '1.2rem',
					}}
				>
					{title}
				</Typography>
			)
		} else if (centerComponent) {
			return centerComponent
		}

		return undefined
	}

	return (
		<Box
			ref={ref}
			sx={{ inset: 0 }}
			position='sticky'
			top={0}
			width={'100%'}
			zIndex={1000}
		>
			<Helmet>
				<title>{props.title || 'JOYNER'}</title>
			</Helmet>
			<AppBar
				position='sticky'
				sx={{
					boxShadow: 'none',
					height: '100px',
					backgroundColor: '#333333',
				}}
			>
				<div
					style={{
						textAlign: 'center',
						backgroundColor: '#333333',
						paddingTop: '15px',
					}}
					onClick={() => window.location.reload()}
				>
					<img
						data-intro='Tap the Joyner logo to refresh Joyner at any time.'
						alt='hero'
						src={logo}
						style={{ width: '120px' }}
					/>
				</div>

				{headerDefined && (
					<Toolbar
						style={{
							display: 'flex',
							fontSize: '1.2em',
							backgroundColor: '#333333',
							height: '50px',
							minHeight: '50px',
							padding: '5px 0px',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								minWidth: '80px',
								maxWidth: '80px',
							}}
						>
							{props.leftComponent}
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{getTitleValue()}
						</div>
						<div
							style={{
								textAlign: 'center',
								minWidth: '80px',
								maxWidth: '80px',
							}}
						>
							{props.rightComponent}
						</div>
					</Toolbar>
				)}
			</AppBar>
			{props.subheader && props.subheader}
		</Box>
	)
})

AppHeader.displayName = 'AppHeader'

export default AppHeader
