import React, { useState, useEffect } from 'react'
import { List, Typography, Divider, Button } from '@mui/material'
import { useGetManyByGroupId } from 'api/polls/getManyByGroupId'
import { useUserContext } from 'contexts/UserContext'
import { useNavigate } from 'react-router'
import { Poll } from 'lib/supabase'
import PollsItem from './PollsItem'

const Polls: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	const navigate = useNavigate()

	const polls: Poll[] =
		useGetManyByGroupId({
			groupId: activeGroup?.id,
			options: { enabled: !!profile },
		}).data || []

	const onClick = (pollId: string) => {
		navigate(`/poll/${pollId}`)
	}

	return (
		<div style={{}}>
			{/* <div style={{ textAlign: 'center', marginTop: '20px' }}>
				<Typography variant='h6'>Group Polls</Typography>
			</div> */}
			<List>
				{polls.length > 0 ? (
					<>
						<Divider />
						{polls &&
							polls.map((poll: Poll) => (
								<PollsItem
									key={poll.id}
									poll={poll}
									onClick={(pollId: string) =>
										onClick(pollId)
									}
								/>
							))}
					</>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Typography paddingTop={'10px'}>
							You group has no polls.
						</Typography>
						<Typography
							marginTop={'20px'}
							marginBottom={'20px'}
							paddingX={'40px'}
						>
							To create a poll tap the&nbsp;
							<strong>Create New Poll</strong> button below.
						</Typography>
					</div>
				)}
			</List>
		</div>
	)
}

export default Polls
