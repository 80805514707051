import React, { useState, useEffect } from 'react'
import {
	SwipeableDrawer,
	AppBar,
	Stack,
	Button,
	Typography,
	Skeleton,
	Avatar,
} from '@mui/material'
import type { Group, Profile } from 'lib/supabase'
import { grey } from '@mui/material/colors'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { stringAvatar } from 'utils'

type Props = {
	chatId: string
	isOpen: boolean
	participants: Profile[]
	onOpen: () => void
	onClose: () => void
	onDeleteConversation: () => void
	onRemoveParticipant: (profileId: string) => void
}

const ConversationInfoDrawer: React.FC<Props> = ({
	chatId,
	isOpen,
	participants,
	onOpen,
	onClose,
	onDeleteConversation,
	onRemoveParticipant,
}) => {
	const drawerBleeding = 0

	const Root = styled('div')(({ theme }) => ({
		height: '100%',
		backgroundColor: grey[100],
		...theme.applyStyles('dark', {
			backgroundColor: theme.palette.background.default,
		}),
	}))

	return (
		<Root>
			<CssBaseline />
			<Global
				styles={{
					'.MuiDrawer-root > .MuiPaper-root': {
						height: `calc(75% - ${drawerBleeding}px)`,
						overflow: 'visible',
					},
				}}
			/>

			<SwipeableDrawer
				anchor={'bottom'}
				open={isOpen}
				onClose={onClose}
				onOpen={onOpen}
				swipeAreaWidth={70}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<div
					style={{
						width: 250,
						paddingTop: 'env(safe-area-inset-top)',
						paddingBottom: 'env(safe-area-inset-bottom)',
					}}
				>
					<div
						style={{
							height: '70px',
							borderRadius: '20px 20px 0px 0px',
							position: 'absolute',
							width: '100%',
							top: '-40px',
							backgroundColor: '#ffffff',
						}}
					>
						<Stack
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							marginTop={'10px'}
						>
							<Skeleton
								variant={'rounded'}
								width={40}
								height={8}
								animation={false}
							/>
							<Typography
								paddingTop={'10px'}
								fontWeight={'bold'}
								fontSize={'1.2rem'}
							>
								Conversation Info
							</Typography>
						</Stack>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '10px',
						marginTop: '60px',
						padding: '0px 20px',
					}}
				>
					<Typography fontWeight={'bold'}>Participants</Typography>
					<div>
						{participants.map((participant) => (
							<div
								key={participant.id}
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: '10px',
									justifyContent: 'start',
									paddingBottom: '20px',
									alignItems: 'center',
								}}
							>
								<Avatar
									key={participant.full_name}
									style={{
										width: 36,
										height: 36,
										fontSize: '1.0rem',
										textTransform: 'uppercase',
									}}
									{...stringAvatar(participant.full_name)}
								/>
								<Typography>{participant.full_name}</Typography>
							</div>
						))}
					</div>
				</div>
				<AppBar
					position='absolute'
					sx={{
						top: 'auto',
						bottom: 0,
						background: '#ffffff',
						paddingBottom: '60px',
					}}
				>
					<Stack
						direction='column'
						gap={2}
						justifyContent='center'
						alignItems='center'
					>
						<Button
							variant='outlined'
							color='error'
							fullWidth
							style={{ marginTop: '10px', maxWidth: '300px' }}
							onClick={onDeleteConversation}
						>
							Delete Conversation
						</Button>
					</Stack>
				</AppBar>
			</SwipeableDrawer>
		</Root>
	)
}

export default ConversationInfoDrawer
