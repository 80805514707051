import { TABLE_PRIVATE_CHATS, PrivateChat } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'
import { getGeneric } from '../generic'
import queryClient from '../../lib/reactQuery'
import type { QueryConfig } from '../../lib/reactQuery'

export const getQueryKey = ({ conversationId }: GetConversationArgs) => [
	'conversation',
	conversationId,
]

type GetConversationArgs = {
	conversationId: string
}
const getConversationFunction = ({ conversationId }: GetConversationArgs) =>
	getGeneric<PrivateChat>({
		tableName: TABLE_PRIVATE_CHATS,
		id: conversationId,
	})

export const getConversation = async ({
	conversationId,
}: GetConversationArgs) =>
	queryClient.fetchQuery({
		queryKey: getQueryKey({ conversationId }),
		queryFn: () => getConversationFunction({ conversationId }),
	})

type UseGetConversationOptions = {
	conversationId: string
	options?: QueryConfig<typeof getConversationFunction>
}
export const useGetConversation = ({
	conversationId,
	options,
}: UseGetConversationOptions) => {
	return useQuery({
		...options,
		queryKey: getQueryKey({ conversationId }),
		queryFn: () => getConversationFunction({ conversationId }),
	})
}
