import { useQuery } from '@tanstack/react-query'
import queryClient, { type QueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_PRIVATE_CHATS } from 'lib/supabase'

export const getChatsQueryKey = ({ userId, groupId }: GetChatsByUSerIdArgs) => [
	'user',
	userId,
	'group',
	groupId,
	'converssations',
]

type GetChatsByUSerIdArgs = {
	userId: string
	groupId: string
}
const getChatsByUserIdFunction = async ({
	userId,
	groupId,
}: GetChatsByUSerIdArgs) => {
	const { data, error } = await supabase
		.from(TABLE_PRIVATE_CHATS)
		.select('*')
		.eq('group_id', groupId)
		.or(`participants.cs.{${userId}}`)

	if (error) throw error

	return data
}

export const getChatsByUserId = async ({
	userId,
	groupId,
}: GetChatsByUSerIdArgs) =>
	queryClient.fetchQuery({
		queryKey: ['chatsByUserId', userId],
		queryFn: () => getChatsByUserIdFunction({ userId, groupId }),
	})

type UseGetChatsByUserIdOptions = {
	userId: string
	groupId: string
	options?: QueryConfig<typeof getChatsByUserIdFunction>
}
export const useGetChatsByUserId = ({
	userId,
	groupId,
	options,
}: UseGetChatsByUserIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['chatsByUserId', userId, groupId],
		queryFn: () => getChatsByUserIdFunction({ userId, groupId }),
	})
}
