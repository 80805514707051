import { useQuery } from '@tanstack/react-query'
import queryClient, { type QueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_PRIVATE_CHATS } from 'lib/supabase'

type GetConversationByIdArgs = {
	chatId: string
}
const getConversationByIdFunction = async ({
	chatId,
}: GetConversationByIdArgs) => {
	const { data, error } = await supabase
		.from(TABLE_PRIVATE_CHATS)
		.select('*')
		.eq('id', chatId)
		.single()

	if (error) throw error

	return data
}

export const getConversationById = async ({
	chatId,
}: GetConversationByIdArgs) =>
	queryClient.fetchQuery({
		queryKey: ['chatById', chatId],
		queryFn: () => getConversationByIdFunction({ chatId }),
	})

type UseGetConversationByIdOptions = {
	chatId: string
	options?: QueryConfig<typeof getConversationByIdFunction>
}
export const useGetConversationById = ({
	chatId,
	options,
}: UseGetConversationByIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['chatById', chatId],
		queryFn: () => getConversationByIdFunction({ chatId }),
	})
}
