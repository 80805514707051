import React from 'react'
import AppHeader from '../../components/AppHeader'
import GroupTabs from './GroupTabs'
import { Box, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router'
import { useUserContext } from '../../contexts/UserContext'
import { useGetGroup } from 'api/group'
import Loading from 'components/Loading'
import ErrorComponent from 'components/ErrorComponent'

const GroupPage: React.FC = () => {
	const navigate = useNavigate()
	const { groupId, tabToOpen, referredBy } = useParams()
	const { user, profile } = useUserContext()
	const group = useGetGroup({ groupId, options: { enabled: !!groupId } })

	const handleBack = () => {
		if (referredBy === 'dashboard') {
			navigate('/')
		} else if (referredBy === 'groups') {
			navigate('/groups')
		} else {
			navigate(-1)
		}
	}

	return (
		<>
			<Box>
				<AppHeader
					leftComponent={
						<IconButton
							sx={{ color: '#ffffff' }}
							onClick={handleBack}
						>
							<ArrowBackIcon />
						</IconButton>
					}
					title={group.data?.name || ''}
				/>

				{group.isSuccess ? (
					<GroupTabs
						group={group.data}
						currentProfile={profile}
						isAdmin={group.data.admin == user?.id}
						tabToOpen='members'
					/>
				) : group.isError ? (
					<ErrorComponent />
				) : (
					<Loading />
				)}
			</Box>
		</>
	)
}

export default GroupPage
