import React from 'react'
import {
	SwipeableDrawer,
	Button,
	Typography,
	Chip,
	IconButton,
	Stack,
	AppBar,
	List,
	ListItem,
	ListItemText,
	Divider,
	Skeleton,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import CancelIcon from '@mui/icons-material/Cancel'
import { Poll, PollChoice } from 'lib/supabase'
import { useGetManyByGroupId } from 'api/profile'

type Props = {
	isOpen: boolean
	poll: Poll | undefined
	choices: PollChoice[] | undefined
	onResetPoll: () => void
	onClosePoll: () => void
	onOpen: () => void
	onClose: () => void
}

const PollResultsDrawer: React.FC<Props> = ({
	isOpen,
	poll,
	choices,
	onResetPoll,
	onClosePoll,
	onOpen,
	onClose,
}) => {
	const drawerBleeding = 0

	const Root = styled('div')(({ theme }) => ({
		height: '100%',
		backgroundColor: grey[100],
		...theme.applyStyles('dark', {
			backgroundColor: theme.palette.background.default,
		}),
	}))

	// const StyledBox = styled('div')(({ theme }) => ({
	// 	backgroundColor: '#fff',
	// 	...theme.applyStyles('dark', {
	// 		backgroundColor: grey[800],
	// 	}),
	// }))

	// const Puller = styled('div')(({ theme }) => ({
	// 	width: 30,
	// 	height: 6,
	// 	backgroundColor: grey[300],
	// 	borderRadius: 3,
	// 	position: 'absolute',
	// 	top: 8,
	// 	left: 'calc(50% - 15px)',
	// 	...theme.applyStyles('dark', {
	// 		backgroundColor: grey[900],
	// 	}),
	// }))

	// get all group members for the group
	const members = useGetManyByGroupId({
		groupId: poll?.group_id || '',
	})
	if (!members.data) return null

	const getProfileNames = (votes: string[]) => {
		const voters =
			members.data?.filter((member) => votes.includes(member.id || '')) ||
			[]

		return (
			<div>
				{voters.map((voter) => (
					<Chip key={voter.id} label={voter.full_name} />
				))}
			</div>
		)
	}

	const getVoteCount = (votes: number) => {
		const plural = votes === 1 ? 'vote' : 'votes'

		return (
			<div style={{ padding: '10px 0' }}>
				<Stack direction='column' alignItems='center'>
					<Typography>
						{votes} {plural}
					</Typography>
				</Stack>
			</div>
		)
	}

	// DOUBLE CHECK FOR POLL
	if (!poll) {
		console.log('PollResultsDrawer.tsx: poll is null')
		return null
	}

	return (
		<Root>
			<CssBaseline />
			<Global
				styles={{
					'.MuiDrawer-root > .MuiPaper-root': {
						height: `calc(75% - ${drawerBleeding}px)`,
						overflow: 'visible',
					},
				}}
			/>
			<SwipeableDrawer
				anchor={'bottom'}
				open={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				swipeAreaWidth={70}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<div
					style={{
						width: '100%',
						paddingTop: 'env(safe-area-inset-top)',
						paddingBottom: 'env(safe-area-inset-bottom)',
					}}
				>
					<div
						style={{
							height: '70px',
							borderRadius: '20px 20px 0px 0px',
							position: 'absolute',
							width: '100%',
							top: '-40px',
							backgroundColor: '#ffffff',
						}}
					>
						<Stack
							direction={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							marginTop={'10px'}
						>
							<Skeleton
								variant={'rounded'}
								width={40}
								height={8}
								animation={false}
							/>
							<Typography
								paddingTop={'10px'}
								fontWeight={'bold'}
								fontSize={'1.2rem'}
							>
								Poll Results
							</Typography>
						</Stack>
					</div>
					<div style={{ margin: '40px 20px 0px 20px' }}>
						<List>
							<Divider component='li' />

							{choices?.map(
								(choice, index) =>
									choice.id !== '' && (
										<div key={index}>
											<ListItem
												key={index}
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'baseline',
													justifyContent:
														'space-between',
												}}
												secondaryAction={
													choice.choice != '' &&
													getVoteCount(
														choice.votes.length
													)
												}
											>
												<ListItemText
													primaryTypographyProps={{
														variant: 'h6',
													}}
													primary={choice.choice}
													secondary={
														choice.choice != '' &&
														getProfileNames(
															choice.votes
														)
													}
												/>
											</ListItem>
											<Divider component='li' />
										</div>
									)
							)}
						</List>
					</div>
					<AppBar
						position='absolute'
						sx={{
							top: 'auto',
							bottom: 0,
							background: '#ffffff',
							paddingBottom: '60px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '5px',
								alignItems: 'center',
								margin: '10px 40px',
							}}
						>
							<Button
								fullWidth
								disabled={poll?.status === 'closed'}
								variant='outlined'
								onClick={onResetPoll}
							>
								Reset Poll
							</Button>
							<Button
								fullWidth
								disabled={poll?.status === 'closed'}
								variant='outlined'
								onClick={onClosePoll}
							>
								Close Poll
							</Button>
						</div>
					</AppBar>
				</div>
			</SwipeableDrawer>
		</Root>
	)
}

export default PollResultsDrawer
