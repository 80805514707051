import { useEffect } from 'react'
import {
	getInfiniteByChatId,
	getInfiniteQueryKeyByChatId,
	useCreateMessage,
	useDeleteMessage,
	//useDeleteConversation,
} from 'api/messages'
import { deleteGeneric } from '../api/generic'
import queryClient from 'lib/reactQuery'
import supabase, {
	type Message,
	TABLE_MESSAGES,
	TABLE_PRIVATE_CHATS,
} from 'lib/supabase'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { DEFAULT_ERROR_MESSAGE } from 'utils'
import { useUserContext } from 'contexts/UserContext'

type UseMessagesByChatArgs = {
	chatId: string
	groupId: string
}
const useMessagesByChat = ({ chatId, groupId }: UseMessagesByChatArgs) => {
	const { profile } = useUserContext()
	const { showSnackbar } = useSnackbarContext()
	const createMessage = useCreateMessage()
	const deleteMessage = useDeleteMessage()
	// const deleteConversation = useDeleteConversation()
	const messages = getInfiniteByChatId({
		chatId: chatId,
		groupId: groupId,
	})

	const addMessageToFrontOfCache = (message: Message) => {
		// lazily add the new message to the front of the cache
		// when the cache is next queried, the new message will be included
		// and the structure will be reset
		queryClient.setQueryData<typeof messages.data>(
			getInfiniteQueryKeyByChatId(groupId, chatId),
			(oldData) => {
				const newPage = oldData ? oldData.pages[0] : []
				newPage.unshift(message)
				return {
					pageParams: oldData ? oldData.pageParams : [0],
					pages: oldData
						? [newPage, ...oldData.pages.slice(1)]
						: [newPage],
				}
			}
		)
	}

	// listen for new messages in this activity
	useEffect(() => {
		const sub = supabase
			.channel('messages-db-changes')
			.on(
				'postgres_changes',
				{
					event: '*',
					schema: 'public',
					table: TABLE_MESSAGES,
					// filter: `activity_id=eq.${activityId}`,
				},
				async (payload) => {
					console.log('useMessagesByChat::Payload:', payload)

					if (payload.eventType === 'DELETE') {
						queryClient.invalidateQueries({
							queryKey: getInfiniteQueryKeyByChatId(
								groupId,
								chatId
							),
						})
					}

					if (payload.eventType === 'INSERT') {
						const message = payload.new as Message
						if (message.sender !== profile?.id)
							addMessageToFrontOfCache(message)
					}
				}
			)
			.subscribe()

		return () => {
			sub.unsubscribe()
		}
	}, [supabase, messages.data, chatId])

	const sendMessage = async (message: Message) => {
		// save previous cache to restore if sending fails
		const prevMessages = queryClient.getQueryData(
			getInfiniteQueryKeyByChatId(groupId, chatId)
		)
		try {
			// update the database behind the scenes
			await createMessage.mutateAsync({ message })

			queryClient.invalidateQueries({
				queryKey: getInfiniteQueryKeyByChatId(groupId, chatId),
			})
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			// revert if something goes wrong
			queryClient.setQueryData(
				getInfiniteQueryKeyByChatId(groupId, chatId),
				prevMessages
			)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	const removeMessage = async (messageId: string) => {
		await deleteMessage.mutateAsync({ messageId: messageId })
		queryClient.invalidateQueries({
			queryKey: getInfiniteQueryKeyByChatId(groupId, chatId),
		})
	}

	const removeConversation = async (chatId: string) => {
		// await deleteConversation.mutateAsync({ chatId: chatId })
		await deleteGeneric({
			tableName: TABLE_PRIVATE_CHATS,
			id: chatId,
		})
		// queryClient.invalidateQueries({
		// 	queryKey: getInfiniteQueryKeyByChatId(chatId, chatId),
		// })
	}

	return {
		...messages,
		sendMessage,
		removeMessage,
		removeConversation,
	}
}

export default useMessagesByChat
