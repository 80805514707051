import { useQuery } from '@tanstack/react-query'
import queryClient from 'lib/reactQuery'
import supabase, { Activity } from 'lib/supabase'
import { TABLE_ACTIVITIES } from 'lib/supabase'
import { getQueryKey } from './get'
import { DateTime } from 'luxon'

import type { QueryConfig } from 'lib/reactQuery'

export const getActivitiesQueryKey = ({ groupId }: GetActivityArgs) => [
	'group',
	groupId,
	'activities',
]

type GetActivityArgs = {
	groupId: string
}

// const expireActivity = async (activity: Activity) => {
// 	const { data, error } = await supabase
// 		.from(TABLE_ACTIVITIES)
// 		.update({ status: 'expired' })
// 		.eq('id', activity.id)

// 	if (error) throw error
// }

const getActivitiesFunction = async ({ groupId }: GetActivityArgs) => {
	if (!groupId) return []

	const { data, error } = await supabase
		.from(TABLE_ACTIVITIES)
		.select()
		.order('datetime', { ascending: true })
		.eq('group_id', groupId)

	if (error) throw error

	// before returning the activities, set status=expired for activities that are expired
	const expiredActivities =
		data.filter(
			(obj) =>
				DateTime.fromISO(obj.datetime) <= DateTime.now() &&
				obj.status === 'active'
		) || []

	if (expiredActivities.length > 0) {
		await Promise.all(
			expiredActivities.map((activity) =>
				supabase
					.from(TABLE_ACTIVITIES)
					.update({ status: 'expired' })
					.eq('id', activity.id)
			)
		)
	}
	return data
}

export const getActivities = async ({ groupId }: GetActivityArgs) =>
	// eslint-disable-next-line @tanstack/query/exhaustive-deps
	queryClient.fetchQuery({
		queryKey: getActivitiesQueryKey({ groupId }),
		queryFn: () => getActivitiesFunction({ groupId }),
	})

type UseGetActivityOptions = {
	groupId: string
	options?: QueryConfig<() => Promise<Activity[]>>
}
export const useGetActivities = ({
	groupId,
	options,
}: UseGetActivityOptions) => {
	// console.log('GetActivities ' + groupId)

	return useQuery({
		...options,
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: getActivitiesQueryKey({ groupId }),
		queryFn: async () => {
			// get the activity ids for the week
			const activities = await getActivitiesFunction({
				groupId,
			})

			activities.forEach((activity) => {
				queryClient.setQueryData(
					getQueryKey({ activityId: activity.id }),
					activity
				)
			})

			return activities
		},
	})
}
