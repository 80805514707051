import React from 'react'
import {
	IconButton,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	AppBar,
} from '@mui/material'
import AppHeader from '../../components/AppHeader'
import { useNavigate } from 'react-router'
import { Venue } from '../../lib/supabase'
import Loading from '../../components/Loading'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useCreateVenue, useGetManyByGroupId } from 'api/venue'
import { useUserContext } from 'contexts/UserContext'
import VenueForm, { VenueFormValues } from 'components/forms/prebuilt/VenueForm'
import { v4 as uuidV4 } from 'uuid'
import { DateTime } from 'luxon'
import ErrorComponent from 'components/ErrorComponent'

type Props = {
	headless?: boolean | false
}

const VenuesTab: React.FC<Props> = (headless) => {
	const { activeGroup } = useUserContext()
	const [creatingVenue, setCreatingVenue] = React.useState(false)
	const venues = useGetManyByGroupId({
		groupId: activeGroup?.id || '',
		options: { enabled: !!activeGroup?.id },
	})
	const navigate = useNavigate()
	const createVenue = useCreateVenue()

	if (venues.isError) {
		console.error(venues.error)
	}

	const handleListClick = (venue?: Venue) => {
		if (venue) navigate(`/venue/${venue?.id}`)
	}

	const handleCreateVenue = async (values: VenueFormValues) => {
		if (activeGroup) {
			const newVenue: Venue = {
				id: uuidV4(),
				group_id: activeGroup.id,
				name: values.name,
				phone: values.phone || null,
				url: values.url || null,
				street: values.street || null,
				city: values.city || null,
				state: values.state || null,
				zip: values.zip || null,
				created_at: DateTime.now().toISO(),
				updated_at: null,
			}

			await createVenue.mutateAsync({ venue: newVenue })
		}
		setCreatingVenue(false)
	}

	return (
		<>
			{!headless && (
				<AppHeader
					leftComponent={
						creatingVenue ? (
							<Button
								sx={{ color: '#ffffff' }}
								onClick={() => setCreatingVenue(false)}
							>
								Cancel
							</Button>
						) : (
							<IconButton
								size='large'
								edge='start'
								style={{ color: 'white' }}
								onClick={() => navigate('-1')}
							>
								<ArrowBackIcon />
							</IconButton>
						)
					}
					title='Venues'
					rightComponent={
						creatingVenue && (
							<Button type='submit' form='venue-form'>
								Save
							</Button>
						)
					}
				/>
			)}

			{creatingVenue ? (
				<VenueForm onSubmit={handleCreateVenue} />
			) : (
				<>
					{venues.isSuccess ? (
						<List>
							{venues.data.map((venue: Venue) => (
								<div key={venue.id}>
									<ListItem
										disableGutters
										onClick={() => handleListClick(venue)}
										secondaryAction={
											<Button
												size='small'
												variant='outlined'
											>
												View
											</Button>
										}
									>
										<ListItemButton>
											<ListItemText
												primary={venue.name}
											/>
										</ListItemButton>
									</ListItem>
									<Divider />
								</div>
							))}
						</List>
					) : venues.isError ? (
						<ErrorComponent />
					) : (
						<Loading />
					)}
				</>
			)}
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '60px',
				}}
			>
				<div
					style={{
						display: creatingVenue ? 'none' : 'flex',
						flexDirection: 'column',
						gap: '5px',
						alignItems: 'center',
						justifyContent: 'center',
						marginTop: '10px',
					}}
				>
					<Button
						variant='contained'
						color='success'
						fullWidth
						style={{ maxWidth: '300px' }}
						onClick={() => navigate('/venue/new')}
					>
						Add Venue
					</Button>
				</div>
			</AppBar>
		</>
	)
}

export default VenuesTab
