import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
	getQueryKey,
	useDeleteActivity,
	useGetActivity,
	useUpdateActivity,
} from 'api/activity'
import { useCreateVenue, useGetManyByGroupId } from 'api/venue'
import { useCreateGuest, useGetManyByActivityId } from 'api/guest'
import { useGetChatsByGroupId } from 'api/conversations'
import { useGetManyByGroupId as useGetMembersOfGroup } from 'api/profile'
import { useGetProfiles } from 'api/profile'
import Loading from 'components/Loading'
import ViewActivity from './ViewActivity'
import type {
	Activity,
	Profile,
	Venue,
	Guest,
	Message,
	PrivateChat,
} from 'lib/supabase'
import EditActivity from './EditActivity'
import supabase, { TABLE_ACTIVITIES } from 'lib/supabase'
import queryClient from 'lib/reactQuery'
import ErrorComponent from 'components/ErrorComponent'
import { Typography } from '@mui/material'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useUserContext } from 'contexts/UserContext'
import { useGroupsContext } from 'contexts/GroupsContext'
import { distributeNotifications } from 'utils'
import QuickAddGuestDialog from 'components/dialogs/QuickAddGuestDialog'
import GroupMembersDialog from 'components/dialogs/GroupMembersDialog'
import SendNotificationDialog from 'components/dialogs/SendNotificationDialog'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'
import { addMessage } from 'api/messages'
import { addConversation } from 'api/conversations'

const ActivityPage: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	const { userIsAdminOfGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()
	const [editing, setEditing] = useState(false)
	const [deletePending, setDeletePending] = useState(false)
	const updateActivity = useUpdateActivity()
	const deleteActivity = useDeleteActivity()
	const createVenue = useCreateVenue()
	const createGuest = useCreateGuest()
	const [showGuestQuickAdd, setShowGuestQuickAdd] = useState(false)
	const [showGroupMembers, setShowGroupMembers] = useState(false)
	const [showSendMessage, setShowSendMessage] = useState(false)
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
		useState(false)
	const { activityId, referredBy } = useParams()
	const activity = useGetActivity({
		activityId: activityId!,
		options: { enabled: !!activityId },
	})

	const venues = useGetManyByGroupId({
		groupId: activeGroup?.id,
		options: { enabled: !!activeGroup },
	})

	if (activity.isError || venues.isError) {
		console.error(activity.error)
	}

	const { data: conversations } = useGetChatsByGroupId({
		groupId: activeGroup?.id || '',
		options: { enabled: !!activeGroup?.id },
	})

	const { data: members } = useGetMembersOfGroup({
		groupId: activeGroup?.id || '',
		options: { enabled: !!activeGroup?.id },
	})

	const tins = useGetProfiles({
		profileIds: activity?.data?.ins || [],
		options: { enabled: activity.isSuccess },
	})
	const ins = tins
		.filter((user) => user.isSuccess)
		.map((user) => user.data) as Profile[]

	const guests = useGetManyByActivityId({
		activityId: activityId!,
		options: { enabled: !!activityId },
	})

	let gins: Profile[] = []
	if (guests.data) {
		gins = guests.data.filter((g: Profile) =>
			activity?.data?.ins.find((i) => i === g.id)
		)
	}
	const combinedIns = [...(ins || []), ...(gins || [])]

	const outs = useGetProfiles({
		profileIds: activity?.data?.outs || [],
		options: { enabled: activity.isSuccess },
	})

	const venue = useMemo(() => {
		return venues.data?.find((v) => v.id === activity.data?.venue_id)
	}, [venues.data, activity.data?.venue_id])

	useEffect(() => {
		if (activity.isSuccess) {
			const chan = supabase
				.channel('db-changes')
				.on(
					'postgres_changes',
					{
						event: '*',
						schema: 'public',
						table: TABLE_ACTIVITIES,
					},
					async (payload) => {
						if (payload.eventType === 'DELETE') {
							console.log(
								'ActivityPage::Subscription: ',
								payload.old.id,
								activity.data.id
							)
						}

						if (payload.eventType === 'INSERT') {
							const newActivity = payload.new as Activity
							if (
								newActivity &&
								newActivity.id === activity.data.id
							) {
								await queryClient.setQueryData(
									getQueryKey({ activityId: newActivity.id }),
									payload.new
								)
							}
						}
					}
				)
				.subscribe()

			return () => {
				chan.unsubscribe()
			}
		}
	}, [activity])

	const handleEdit = () => {
		setEditing(true)
	}

	const handleCancel = (dialogAction: string) => {
		if (dialogAction === 'delete' || dialogAction === 'new') {
			if (deletePending && activityId)
				deleteActivity.mutate({ activityId: activityId })
			navigate('/')
		} else if (dialogAction === 'update') {
			setEditing(false)
		}
	}

	const handleSave = async (newActivity: Partial<Omit<Activity, 'id'>>) => {
		if (!activity.isSuccess) return

		console.log('ActivityPage::handleSave called')
		await updateActivity.mutateAsync({
			activityId: activity.data.id,
			activity: newActivity,
		})
	}

	const handleRemoveIn = async (uid: string) => {
		if (!activity.data) return
		const newIns = activity.data.ins
		const index = newIns.findIndex((i) => i === uid)
		newIns.splice(index, 1)
		await updateActivity.mutateAsync({
			activityId: activity.data.id,
			activity: {
				ins: newIns,
				outs: activity.data.outs,
			},
		})
	}

	const showAddGuest = () => {
		setShowGuestQuickAdd(true)
	}

	const handleAddGuest = async (newGuest: Guest) => {
		if (activityId) newGuest.activity_id = activityId
		if (profile?.id) newGuest.created_by = profile?.id
		newGuest.created_at = DateTime.now().toISO()

		createGuest.mutate({ guest: newGuest })
		if (activity.data) {
			const newIns = activity.data.ins
			newIns.push(newGuest.id)
			await updateActivity.mutateAsync({
				activityId: activity.data.id,
				activity: {
					ins: newIns,
					outs: activity.data.outs,
				},
			})
		}
		setShowGuestQuickAdd(false)
	}

	const showAddMember = () => {
		setShowGroupMembers(true)
	}

	const showSendMessageDialog = () => {
		setShowSendMessage(true)
	}

	const handleAddMember = async (selected: string[]) => {
		if (!activity.data) return
		const newIns = activity.data.ins
		selected.map((member) => {
			const index = newIns.findIndex((i) => i === member)
			if (index === -1) newIns.push(member)
		})
		await updateActivity.mutateAsync({
			activityId: activity.data.id,
			activity: {
				ins: newIns,
				outs: activity.data.outs,
			},
		})

		setShowGroupMembers(false)
	}

	const handleDeleteActivity = () => {
		if (activityId && activity.isSuccess) {
			console.log('ActivityPage::handleDeleteActivity - delete activity')
			// deleteActivity.mutate({ activityId: activityId })
			setDeletePending(true)
		}
	}

	const handleCreateVenue = async (venue: Venue): Promise<Error | void> => {
		try {
			await createVenue.mutateAsync({ venue })
		} catch (err) {
			return err as Error
		}
	}

	const handleSendNotifications = async (
		selected: string[],
		message: string,
		type: string
	) => {
		if (!activity.data || !profile || !activeGroup) return

		console.log(
			'ActivityPage::handleSendNotifications - send notifications to selected members for action type ' +
				type
		)
		distributeNotifications(
			'activity',
			type,
			selected,
			message,
			profile.full_name,
			activeGroup?.id,
			activity.data.id
		)

		// add message to either private or group conversations
		let newMessage: Message = {
			id: v4().toString(),
			activity_id: activity.data.id,
			chat_id: null,
			body: message,
			created_at: DateTime.now().toISO(),
			sender: profile.id,
			group_id: activeGroup.id,
		}
		const filtered = members?.length != selected.length
		if (filtered) {
			const chatExists = conversations?.find(
				(conversation) =>
					conversation.participants.length === selected.length &&
					conversation.participants.every((participant) =>
						selected.includes(participant)
					)
			) as PrivateChat | null

			if (chatExists) {
				newMessage = {
					...newMessage,
					chat_id: chatExists.id,
				}
				addMessage(newMessage)
			} else {
				const newChatId = v4()
				const newChat: PrivateChat = {
					id: newChatId,
					created_at: DateTime.now().toISO(),
					updated_at: null,
					created_by: profile.id,
					group_id: activeGroup.id,
					participants: selected,
					status: 'active',
				}
				addConversation(newChat).then(() => {
					newMessage = {
						...newMessage,
						chat_id: newChatId,
					}
					addMessage(newMessage)
				})
			}
		} else {
			addMessage(newMessage)
		}

		showSnackbar('Notifications sent to selected members', 'success')
		if (type === 'message') setShowSendMessage(false)
		if (type === 'update') setEditing(false)
		if (type === 'delete') {
			if (deletePending && activityId)
				deleteActivity.mutate({ activityId: activityId })
			navigate('/')
		}
	}

	const handleBack = () => {
		console.log('ActivityPage::handleBack::referredBy::', referredBy)
		if (referredBy === 'dashboard') {
			navigate('/')
		} else if (referredBy === 'chatpage') {
			navigate(-1)
		} else {
			navigate(-1)
		}
	}

	return (
		<main>
			{activity.isSuccess && venues.isSuccess && venue && profile ? (
				editing ? (
					<EditActivity
						venues={venues.data}
						activity={activity.data}
						onSave={handleSave}
						onCancel={(dialogAction: string) =>
							handleCancel(dialogAction)
						}
						onDeleteActivity={handleDeleteActivity}
						onCreateVenue={handleCreateVenue}
						onSendNotifications={handleSendNotifications}
					/>
				) : (
					<ViewActivity
						activity={activity.data}
						canViewButtons={
							activity.data.activity_owner_id === profile.id ||
							userIsAdminOfGroup(activity.data.group_id)
						}
						ins={combinedIns}
						outs={
							outs
								.filter((user) => user.isSuccess)
								.map((user) => user.data) as Profile[]
						}
						venue={venue}
						onBack={handleBack}
						onEdit={handleEdit}
						onDelete={handleDeleteActivity}
						onRemoveIn={handleRemoveIn}
						onShowAddGuest={showAddGuest}
						onShowAddMember={showAddMember}
						onShowSendMessage={showSendMessageDialog}
					/>
				)
			) : deletePending ? (
				<Typography>Activity is Pending Delete</Typography>
			) : activity.isError || venues.isError ? (
				<ErrorComponent />
			) : (
				<Loading />
			)}

			<QuickAddGuestDialog
				isOpen={showGuestQuickAdd}
				onCancel={() => setShowGuestQuickAdd(false)}
				onSubmit={(newGuest: Guest) => handleAddGuest(newGuest)}
			/>

			<GroupMembersDialog
				isOpen={showGroupMembers}
				onCancel={() => setShowGroupMembers(false)}
				onList={(selected: string[]) => handleAddMember(selected)}
				activeGroup={activeGroup?.id}
				ins={activity?.data?.ins}
				outs={activity?.data?.outs}
				title='Add Member(s) to Activity'
				buttonText='Add Selected'
				selectAll={false}
				filterList={true}
				currentUserId={profile?.id}
				forceCheckCurrentUser={false}
				currentList={[]}
			/>

			<SendNotificationDialog
				isOpen={showSendMessage}
				onCancel={() => setShowSendMessage(false)}
				activeGroup={activeGroup?.id}
				selectAll={true}
				forceSendAll={false}
				title='Send Message'
				textFieldLabel='Message to Send'
				showConfirm={false}
				showConfirmText=''
				dialogType='notify'
				objectType='activity'
				dialogAction='message'
				onUpdateDeleteAction={() => {}}
				onSendNotifications={(selected: string[], message: string) => {
					handleSendNotifications(selected, message, 'message')
				}}
			/>

			<ConfirmationDialog
				isOpen={showConfirmDeleteDialog}
				title='Delete Activity'
				confirmButtonText='Delete'
				content='Are you sure you want to delete this activity?'
				onConfirm={handleDeleteActivity}
				onClose={() => setShowConfirmDeleteDialog(false)}
			/>
		</main>
	)
}

export default ActivityPage
