import { TABLE_ACTIVITIES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import queryClient from '../../lib/reactQuery'
import { deleteGeneric } from '../generic'
import type { MutationConfig } from '../../lib/reactQuery'
import type { Activity } from '../../lib/supabase'

type DeleteActivityArgs = {
	activityId: string
}
export const deleteActivity = async ({ activityId }: DeleteActivityArgs) =>
	deleteGeneric({ tableName: TABLE_ACTIVITIES, id: activityId })

type UseDeleteActivityOptions = {
	options?: MutationConfig<typeof deleteActivity>
}
export const useDeleteActivity = ({
	options,
}: UseDeleteActivityOptions = {}) => {
	return useMutation({
		onMutate: async (activityId) => {
			await queryClient.cancelQueries({
				queryKey: ['activity', activityId],
			})
			const previousActivity = queryClient.getQueryData<Activity>([
				'activity',
				activityId,
			])

			return { previousActivity }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			if (context?.previousActivity) {
				queryClient.setQueryData(
					['activity', context.previousActivity.id],
					context.previousActivity
				)
			}
		},
		onSuccess: (activityId) => {
			queryClient.invalidateQueries({
				queryKey: ['activity', activityId],
			})

			// don't have access to the week so we just invalidate ever week
			queryClient.invalidateQueries({ queryKey: ['activities'] })
		},
		...options,
		mutationFn: deleteActivity,
	})
}
