import supabase from '../../lib/supabase'
import { PrivateChat } from '../../lib/supabase'

export const addConversation = async (conversation: PrivateChat) => {
	const { data, error } = await supabase
		.from('private_chats')
		.insert([conversation])

	if (error) {
		throw new Error(error.message)
	}
	return data
}
