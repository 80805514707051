import React, { useState, useEffect } from 'react'
import { DEFAULT_ERROR_MESSAGE } from 'utils'
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Group, Profile } from '../../lib/supabase'
import { useGetManyByGroupId } from 'api/profile'
import { useUpdateGroup } from 'api/group'
import { useUserContext } from 'contexts/UserContext'
import { useNavigate, useParams } from 'react-router'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useGroupsContext } from 'contexts/GroupsContext'
import Loading from 'components/Loading'
import VenuesTab from './VenuesTab'
import MembersTab from './MembersTab'
import PropertiesTab from './PropertiesTab'
import useRouteMatch from 'hooks/useRouteMatch'
import { ref } from 'yup'

type Props = {
	group: Group
	currentProfile?: Profile
	isAdmin: boolean
	tabToOpen: string | TabIndexes.MEMBERS | ''
}

enum TabIndexes {
	MEMBERS = 'members',
	PROPERTIES = 'properties',
	VENUES = 'venues',
}

type FormValues = {
	groupName: string
	activityName: string
}

const GroupTabs: React.FC<Props> = (props) => {
	const { profile, setActiveGroup } = useUserContext()
	const { showSnackbar } = useSnackbarContext()
	const { groups, removeUserFromGroup, deleteGroup } = useGroupsContext()
	const members = useGetManyByGroupId({ groupId: props.group.id })
	const routeMatch = useRouteMatch(['/', '/polls', '/messages'])
	const [tabIndex, setTabIndex] = useState(
		routeMatch?.pattern?.path || props.tabToOpen || TabIndexes.MEMBERS
	)
	const updateGroup = useUpdateGroup()
	const navigate = useNavigate()

	const { groupId, tabToOpen, referredBy } = useParams()
	useEffect(() => {
		if (tabToOpen) {
			if (tabToOpen === 'members') setTabIndex(TabIndexes.MEMBERS)
			if (tabToOpen === 'properties') setTabIndex(TabIndexes.PROPERTIES)
			if (tabToOpen === 'venues') setTabIndex(TabIndexes.VENUES)
		} else {
			setTabIndex(TabIndexes.MEMBERS)
		}
	}, [tabToOpen])

	const handleRemoveMember = async (profileId: string) => {
		if (groups.isSuccess) {
			try {
				await removeUserFromGroup({
					userId: profileId,
					groupId: props.group.id,
				})

				showSnackbar('Member removed', 'success')
			} catch (err) {
				if (err instanceof Error) {
					console.error(err.message)
				}
				showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			}
		}
	}

	const handleLeaveGroup = async (profileId: string) => {
		if (!groups.isSuccess) return

		try {
			await removeUserFromGroup({
				userId: profileId,
				groupId: props.group.id,
			})

			const newGroup = groups.data.filter(
				(group) => group.id !== props.group.id
			)?.[0]
			await setActiveGroup(newGroup ? newGroup.id : null)
			showSnackbar('Successfully left group', 'success')
			navigate('/')
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	const handleDeleteGroup = async (groupId: string) => {
		if (groups.isSuccess) {
			await deleteGroup(groupId)

			const remainingGroups = groups.data.filter(
				(group) => group.id !== props.group.id
			)
			setActiveGroup(
				remainingGroups.length ? remainingGroups[0].id : null
			)
			navigate('/')
		}
	}

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		navigate(`/group/${groupId}/${newValue}/${referredBy}`)
	}

	const handleGroupUpdate = (values: FormValues, defaultTimezone: string) => {
		updateGroup.mutate({
			group: {
				...props.group,
				name: values.groupName,
				activity_name: values.activityName,
				default_timezone: defaultTimezone,
			},
		})
	}

	return (
		<>
			{members.isSuccess ? (
				<Box sx={{ width: '100%' }}>
					<TabContext value={tabIndex}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList
								variant='fullWidth'
								centered
								onChange={handleChange}
							>
								<Tab
									label='Members'
									value={TabIndexes.MEMBERS}
								/>
								<Tab
									label='Properties'
									disabled={!props.isAdmin}
									value={TabIndexes.PROPERTIES}
								/>
								<Tab
									label='Venues'
									disabled={!props.isAdmin}
									value={TabIndexes.VENUES}
								/>
							</TabList>
						</Box>

						{/* GROUP MEMBERS TAB PANEL */}
						<TabPanel value={TabIndexes.MEMBERS}>
							<MembersTab
								isAdmin={props.isAdmin}
								group={props.group}
								currentUser={profile!}
								onRemove={(profileId: string) =>
									handleRemoveMember(profileId)
								}
								onLeave={(profileId: string) =>
									handleLeaveGroup(profileId)
								}
							/>
						</TabPanel>

						{/* GROUP PROPERTIES TAB PANEL */}
						<TabPanel value={TabIndexes.PROPERTIES}>
							<PropertiesTab
								group={props.group}
								onDeleteGroup={(groupId: string) =>
									handleDeleteGroup(groupId)
								}
								onUpdateGroup={(
									updatedGroup: FormValues,
									defaultTimezone: string
								) =>
									handleGroupUpdate(
										updatedGroup,
										defaultTimezone
									)
								}
							/>
						</TabPanel>

						{/* VENUES TAB PANEL */}
						<TabPanel value={TabIndexes.VENUES}>
							<VenuesTab headless={true} />
						</TabPanel>
					</TabContext>
				</Box>
			) : (
				<Loading />
			)}
		</>
	)
}

export default GroupTabs
