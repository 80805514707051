import React, { useState } from 'react'
import { Button, AppBar } from '@mui/material'
import AppHeader from 'components/AppHeader'
import SendNotificationDialog from 'components/dialogs/SendNotificationDialog'
import { Poll, PollChoice } from 'lib/supabase'
import PollForm, {
	PollFormValues,
} from '../../components/forms/prebuilt/PollForm'
import { useUserContext } from 'contexts/UserContext'
import { set } from 'react-hook-form'

type Props = {
	poll: Poll
	choices: PollChoice[]
	onSave: (newPoll: Partial<Poll>, newChoices: PollChoice[]) => void
	onCancel: (dialogAction: string) => void
	onDelete: (pollId: string) => void
	onSendNotifications: (
		selected: string[],
		message: string,
		type: string
	) => void
}

const EditPoll: React.FC<Props> = ({
	poll,
	choices,
	onSave,
	onCancel,
	onDelete,
	onSendNotifications,
}) => {
	type DialogProps = {
		dialogAction: string
		dialogType: string
		objectType: string
		title: string
		textFieldLabel: string
		selectAll: boolean
		forceSendAll: boolean
		showConfirm: boolean
		showConfirmText: string
	}

	const deletePollDialogProps: DialogProps = {
		dialogAction: 'delete',
		dialogType: 'confirm',
		objectType: 'poll',
		title: 'Delete Poll',
		textFieldLabel: 'Additional Information (optional)',
		selectAll: true,
		forceSendAll: true,
		showConfirm: true,
		showConfirmText: 'Confirm Delete Poll',
	}

	const editPollDialogProps: DialogProps = {
		dialogAction: 'update',
		dialogType: 'confirm',
		objectType: 'poll',
		title: 'Update Poll',
		textFieldLabel: 'Additional Information (optional)',
		selectAll: true,
		forceSendAll: true,
		showConfirm: true,
		showConfirmText: 'Confirm Save Changes',
	}

	const [dialogProps, setDialogProps] = useState<DialogProps>({
		dialogAction: '',
		dialogType: '',
		objectType: '',
		title: '',
		textFieldLabel: '',
		selectAll: false,
		forceSendAll: false,
		showConfirm: false,
		showConfirmText: '',
	})

	const [showNotificationDialog, setShowNotificationDialog] =
		React.useState(false)
	const [updatedPoll, setUpdatedPoll] = useState<Partial<Poll> | null>(null)
	const [updatedChoices, setUpdatedChoices] = useState<PollChoice[]>(choices)
	const { profile } = useUserContext()

	const submitForm = (data: PollFormValues) => {
		const newChoices = choices
		if (data.choice_1 !== undefined && data.choice_1 !== '') {
			newChoices[0].choice = data.choice_1
		} else {
			newChoices[0].choice = null
			newChoices[0].votes = []
		}
		if (data.choice_2 !== undefined && data.choice_2 !== '') {
			newChoices[1].choice = data.choice_2
		} else {
			newChoices[1].choice = null
			newChoices[1].votes = []
		}
		if (data.choice_3 !== undefined && data.choice_3 !== '') {
			newChoices[2].choice = data.choice_3
		} else {
			newChoices[2].choice = null
			newChoices[2].votes = []
		}
		if (data.choice_4 !== undefined && data.choice_4 !== '') {
			newChoices[3].choice = data.choice_4
		} else {
			newChoices[3].choice = null
			newChoices[3].votes = []
		}
		if (data.choice_5 !== undefined && data.choice_5 !== '') {
			newChoices[4].choice = data.choice_5
		} else {
			newChoices[4].choice = null
			newChoices[4].votes = []
		}
		if (data.choice_6 !== undefined && data.choice_6 !== '') {
			newChoices[5].choice = data.choice_6
		} else {
			newChoices[5].choice = null
			newChoices[5].votes = []
		}

		const newPoll: Partial<Poll> = {
			title: data.title,
			message: data.message,
			choose_multiple: data.choose_multiple,
		}
		setUpdatedPoll(newPoll)
		setUpdatedChoices(newChoices)
		setDialogProps(editPollDialogProps)
		setShowNotificationDialog(true)
	}

	// const handleClosePoll = async () => {
	// 	console.log('close poll')
	// }

	const handleDelete = () => {
		setDialogProps(deletePollDialogProps)
		setShowNotificationDialog(true)
	}

	const handleCancel = () => {
		onCancel(dialogProps.dialogAction)
	}

	const handleUpdateDeleteAction = async () => {
		// HANDLE DELETE POLL
		if (dialogProps.dialogAction === 'delete') {
			console.log(
				'EditPolll::handleSendNotifications - confirmed delete so delete poll'
			)
			onDelete(poll.id)
		}

		// HANDLE UPDATE POLL
		if (
			dialogProps.dialogAction === 'update' &&
			updatedPoll &&
			updatedChoices
		) {
			console.log(
				'EditPoll::handleSendNotifications - confirmed edit so update poll'
			)
			onSave(updatedPoll, updatedChoices)
			setUpdatedPoll(null)
			setUpdatedChoices([])
		}
	}

	const handleSendNotifications = async (
		sendList: string[],
		addlMessage: string
	) => {
		// // HANDLE DELETE POLL
		// if (dialogProps.dialogAction === 'delete' && isConfirmed) {
		// 	console.log(
		// 		'EditPolll::handleSendNotifications - confirmed delete so delete poll'
		// 	)
		// 	onDelete(poll.id)
		// }

		// // HANDLE UPDATE POLL
		// if (
		// 	dialogProps.dialogAction === 'update' &&
		// 	isConfirmed &&
		// 	updatedPoll &&
		// 	updatedChoices
		// ) {
		// 	console.log(
		// 		'EditPoll::handleSendNotifications - confirmed edit so update poll'
		// 	)
		// 	onSave(updatedPoll, updatedChoices)
		// 	setUpdatedPoll(null)
		// 	setUpdatedChoices([])
		// }

		// SEND NOTIFICATIONS IF NOTIFIFYING
		onSendNotifications(sendList, addlMessage, dialogProps.dialogAction)

		// CLOSE DIALOG
		setShowNotificationDialog(false)
	}

	return (
		<div>
			<AppHeader
				leftComponent={<Button onClick={handleCancel}>Cancel</Button>}
				title='Edit Poll'
			/>
			<div style={{ height: '110vh', overflowY: 'scroll' }}>
				<PollForm
					id='edit-form'
					defaultValues={{
						title: poll?.title || '',
						message: poll?.message || '',
						choose_multiple: poll?.choose_multiple,
						choice_1: choices[0]?.choice || '',
						choice_2: choices[1]?.choice || '',
						choice_3: choices[2]?.choice || '',
						choice_4: choices[3]?.choice || '',
						choice_5: choices[4]?.choice || '',
						choice_6: choices[5]?.choice || '',
					}}
					onSubmit={submitForm}
				/>
			</div>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '5px',
						alignItems: 'center',
						margin: '10px 40px',
					}}
				>
					<Button
						fullWidth
						variant='contained'
						color='primary'
						type='submit'
						form='edit-form'
						style={{ maxWidth: '300px' }}
					>
						Save Changes
					</Button>

					<Button
						variant='outlined'
						color='error'
						fullWidth
						style={{ maxWidth: '300px' }}
						onClick={handleDelete}
					>
						Delete Poll
					</Button>
				</div>
			</AppBar>

			<SendNotificationDialog
				{...dialogProps}
				isOpen={showNotificationDialog}
				onCancel={handleCancel}
				activeGroup={profile?.active_group || undefined}
				onUpdateDeleteAction={handleUpdateDeleteAction}
				onSendNotifications={(selected: string[], message: string) => {
					handleSendNotifications(selected, message)
				}}
			/>
		</div>
	)
}

export default EditPoll
