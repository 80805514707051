import queryClient from 'lib/reactQuery'
import supabase from 'lib/supabase'
import { TABLE_MESSAGES } from 'lib/supabase'
import type { QueryConfig } from 'lib/reactQuery'
import { useQuery } from '@tanstack/react-query'

type GetManyByGroupIdArgs = {
	groupId?: string
}
type GetLatestArgs = {
	groupId?: string
	chatId?: string
}

export const getLatestMessage = async ({ groupId, chatId }: GetLatestArgs) => {
	if (!chatId && !groupId) return []

	if (chatId) {
		const { data, error } = await supabase
			.from(TABLE_MESSAGES)
			.select('*')
			.eq('chat_id', chatId)
			.order('created_at', { ascending: false })
			.limit(1)
			.single()

		if (error) throw error

		return data ? [data] : []
	} else if (groupId) {
		const { data, error } = await supabase
			.from(TABLE_MESSAGES)
			.select('*')
			.eq('group_id', groupId)
			.or('chat_id.is.null')
			.order('created_at', { ascending: false })
			.limit(1)
			.single()

		if (error) throw error

		return data ? [data] : []
	}
}

const getManyByGroupIdFunction = async ({ groupId }: GetManyByGroupIdArgs) => {
	if (!groupId) return []

	const { data, error } = await supabase
		.from(TABLE_MESSAGES)
		.select('*')
		.eq('group_id', groupId)
		.order('created_at', { ascending: false })

	if (error) throw error

	return data
}

export const getManyByGroupId = async ({ groupId }: GetManyByGroupIdArgs) => {
	queryClient.fetchQuery({
		queryKey: ['messages', 'byGroupId', groupId],
		queryFn: () => getManyByGroupIdFunction({ groupId }),
	})
}

type UseGetManyByGroupIdOptions = {
	groupId?: string | ''
	options?: QueryConfig<typeof getManyByGroupIdFunction>
}
export const useGetManyByGroupId = ({
	groupId,
	options,
}: UseGetManyByGroupIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['messages', 'byGroupId', groupId],
		queryFn: () => getManyByGroupIdFunction({ groupId }),
	})
	// }
}
