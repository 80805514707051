import supabase from '../../lib/supabase'
import type { TableNames } from '../../lib/supabase'

type DeleteGenericArgs = {
	tableName: TableNames
	id: string
}

export const deleteGeneric = async ({ tableName, id }: DeleteGenericArgs) => {
	if (id == null || id.length == 0) throw new Error('No id provided')

	const response = await supabase.from(tableName).delete().eq('id', id)
	if (response.error) throw new Error(response.error.message)
	return id
}
