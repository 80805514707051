import React, { useState, useEffect } from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	Checkbox,
} from '@mui/material'
import { getManyByGroupId } from 'api/profile'
import type { Profile } from 'lib/supabase'

type Props = {
	isOpen: boolean
	onCancel: () => void
	onList: (selected: string[]) => void
	activeGroup: string | undefined
	ins: string[] | undefined
	outs: string[] | undefined
	currentList: string[] | []
	title: string
	buttonText: string
	selectAll: boolean
	filterList: boolean
	currentUserId: string | undefined
	forceCheckCurrentUser: boolean
}

const GroupMembersDialog: React.FC<Props> = ({
	isOpen,
	onCancel,
	onList,
	activeGroup,
	ins,
	outs,
	currentList,
	title,
	buttonText,
	selectAll,
	filterList,
	currentUserId,
	forceCheckCurrentUser,
}) => {
	const [checkedMembers, setCheckedMembers] = useState<string[]>([])
	const [members, setMembers] = useState([] as Profile[])

	useEffect(() => {
		if (!activeGroup) return
		if (filterList) {
			getManyByGroupId({ groupId: activeGroup }).then((response) => {
				ins?.map((uid) => {
					const index = response.findIndex(
						(profile) => profile.id === uid
					)
					if (index !== -1) {
						response.splice(index, 1)
					}
				})
				outs?.map((uid) => {
					const index = response.findIndex(
						(profile) => profile.id === uid
					)
					if (index !== -1) {
						response.splice(index, 1)
					}
				})
				setMembers(response)
			})
		} else if (selectAll) {
			getManyByGroupId({ groupId: activeGroup }).then((response) => {
				setCheckedMembers(response.map((member) => member.id))
			})
		} else if (forceCheckCurrentUser && currentUserId) {
			getManyByGroupId({ groupId: activeGroup }).then((response) => {
				setMembers(response)
				setCheckedMembers(currentList)
			})
		} else {
			getManyByGroupId({ groupId: activeGroup }).then((response) => {
				setMembers(response)
				setCheckedMembers(currentList)
			})
		}
	}, [isOpen])

	const handleToggle = (uid: string) => {
		if (forceCheckCurrentUser && currentUserId === uid) return
		const currentIndex = checkedMembers.indexOf(uid)
		const newChecked = [...checkedMembers]
		if (currentIndex === -1) {
			newChecked.push(uid)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setCheckedMembers(newChecked)
	}

	const handleToggleAll = () => {
		if (checkedMembers.length === members.length) {
			if (forceCheckCurrentUser && currentUserId) {
				const temp: string[] = []
				temp.push(currentUserId)
				setCheckedMembers(temp)
			} else {
				setCheckedMembers([])
			}
		} else {
			setCheckedMembers(members.map((member) => member.id))
		}
	}

	const getGroupMembersList = () => {
		return (
			<div>
				<Button onClick={handleToggleAll}>
					Select{' '}
					{checkedMembers.length === members.length
						? ' None'
						: ' All'}
				</Button>
				<List dense>
					{members &&
						members.map((member: Profile) => (
							<ListItem key={member.id} disablePadding>
								<ListItemButton
									dense
									onClick={() => handleToggle(member.id)}
									disabled={
										forceCheckCurrentUser &&
										member.id === currentUserId
									}
								>
									<ListItemIcon
										style={{
											minWidth: '20px',
										}}
									>
										<Checkbox
											edge='start'
											style={{ padding: '0px' }}
											checked={checkedMembers.includes(
												member.id
											)}
											disableRipple
										/>
									</ListItemIcon>
									<ListItemText primary={member.full_name} />
								</ListItemButton>
							</ListItem>
						))}
				</List>
			</div>
		)
	}

	return (
		<Dialog fullWidth open={isOpen} onClose={onCancel}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{activeGroup && getGroupMembersList()}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>Cancel</Button>
				<Button
					variant='contained'
					onClick={() => onList(checkedMembers)}
				>
					{buttonText}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default GroupMembersDialog
