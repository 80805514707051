import { getGeneric } from '../generic'
import { TABLE_POLLS } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'

import type { Poll } from '../../lib/supabase'
import type { QueryConfig } from '../../lib/reactQuery'
import queryClient from '../../lib/reactQuery'

type GetPollArgs = {
	pollId: string
}
const getPollFunction = async ({ pollId }: GetPollArgs) =>
	getGeneric<Poll>({ tableName: TABLE_POLLS, id: pollId })

export const getPoll = async ({ pollId }: GetPollArgs) =>
	queryClient.fetchQuery({
		queryKey: ['poll', pollId],
		queryFn: () => getPollFunction({ pollId }),
	})

type UseGetPollOptions = {
	pollId: string
	options?: QueryConfig<typeof getPollFunction>
}
export const useGetPoll = ({ pollId, options }: UseGetPollOptions) => {
	return useQuery({
		...options,
		queryKey: ['poll', pollId],
		queryFn: () => getPollFunction({ pollId }),
	})
}

export const loadPoll = async (pollId: string) => {
	const data = await getGeneric<Poll>({ tableName: TABLE_POLLS, id: pollId })
	if (!data) {
		throw new Error('Poll not found')
		return null
	}
	return data
}
