import { useInfiniteQuery } from '@tanstack/react-query'
import type { InfiniteQueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_MESSAGES } from 'lib/supabase'

const MESSAGE_FETCH_LIMIT = 20

// keep the messages between groups and activities separate
export const getInfiniteQueryKeyByGroupId = (groupId: string) => [
	'group',
	groupId,
	'messages',
]

type PageParams = number

type FetchMessagesArgs = {
	pageParam: PageParams
	groupId: string
}
const fetchMessages = async ({ pageParam = 0, groupId }: FetchMessagesArgs) => {
	const { data, error } = await supabase
		.from(TABLE_MESSAGES)
		.select()
		.eq('group_id', groupId)
		.or('chat_id.is.null')
		.order('created_at', { ascending: false })
		.range(
			pageParam * MESSAGE_FETCH_LIMIT,
			(pageParam + 1) * MESSAGE_FETCH_LIMIT - 1
		)

	if (error) throw error

	return data
}

type getInfiniteByActivityIdArgs = {
	groupId: string
	options?: InfiniteQueryConfig<typeof fetchMessages, PageParams>
}
export const getInfiniteByGroupId = ({
	groupId,
	options,
}: getInfiniteByActivityIdArgs) => {
	return useInfiniteQuery({
		...options,
		queryKey: getInfiniteQueryKeyByGroupId(groupId),
		queryFn: ({ pageParam }) => fetchMessages({ pageParam, groupId }),
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			return lastPage.length ? lastPageParam + 1 : undefined
		},
	})
}
